import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { withDialogManager } from 'src/components/DialogManager'
import withQueries from 'src/components/HOC/withQueries'
import usePageTitle from 'src/components/hooks/usePageTitle'
import useRedirectByUserRole from 'src/components/hooks/useRedirectByUserRole'
import withRouter from 'src/components/hooks/useRouter'
import LoadingSpinner from 'src/components/LoadingSpinner'
import Context, {
  IPublishedLeaveContext
} from 'src/components/pages/PublishedLeavePage/context'
import ProgressBar from 'src/features/Leave/components/ProgressBar'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import {
  IWithDialogManager,
  IWithQueriesProps,
  PublishedLeaveStage
} from 'src/react-app-env'
import {
  createLeaveRoute,
  errorPageRoute,
  journeyMapRoute,
  timelineRoute
} from 'src/routes/constants'
import { ActivateManagerEnum } from 'src/types/ActivateManagerEnum'
import Workflow, { ws } from 'src/utils/workflow'
import styled, { css } from 'styled-components'
import GetReminders from './components/GetReminders'
import SetupManager from './components/SetupManager'

interface IProps extends IWithQueriesProps, IWithDialogManager {}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => (props.theme.isDesktop ? '24px' : '17px')};
  position: relative;

  ${props =>
    props.theme.isMobile &&
    css`
      height: calc(100% - 16px);
    `}
`

const AlertContent = styled(ReactMarkdown)`
  white-space: pre-wrap;
`

export const PublishedLeavePage = React.memo((props: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { queries } = props
  const [leave, setLeave] = useState<ILeave>(null)
  const [isFetching, setFetching] = useState<boolean>()
  const [showProgress, setShowProgress] = useState<boolean>(true)
  const [activateManager, setActivateManager] = useState(null)
  const workflow: Workflow<PublishedLeaveStage> = useMemo(
    () => ws(['getreminders', 'setupManager']),
    []
  )
  const [currentStage, setCurrentStage] = useState<PublishedLeaveStage>(
    workflow.current
  )
  const progress = useMemo(() => workflow.progress, [workflow.progress])

  const destination = useMemo(
    () =>
      searchParams.get('dest') === 'timeline' ? timelineRoute : journeyMapRoute,
    [searchParams]
  )

  useRedirectByUserRole({ navigate, allowedRole: 'employee' })
  usePageTitle('publishedLeave')

  const userResult: IUseUser = useUser()

  const onShowDialog = useCallback(() => {
    const dialogTKey = `publishedLeave.dialog.managerActivated.${activateManager}`
    props.dialogManager.add({
      title: t(`${dialogTKey}.title`),
      children: (
        <AlertContent>
          {t(`${dialogTKey}.description`, {
            managerEmail: userResult.user.managerEmail
          })}
        </AlertContent>
      ),
      buttons: [
        {
          title: t('common.close'),
          onClick: (): void => undefined
        }
      ]
    })
  }, [props.dialogManager, t, activateManager, userResult.user])

  const fetchLeave = useCallback(async () => {
    try {
      setFetching(true)
      const leaveResult: ILeave =
        await props.queries.fetchLeaveWithActivationInfo({
          notFound: () => navigate(createLeaveRoute)
        })
      setLeave(leaveResult)
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    } finally {
      setFetching(false)
    }
  }, [props, navigate])

  const activateManagerForLeave = useCallback(async () => {
    try {
      await queries.activateManagerForLeave('Onboarding', {
        fetchPolicy: 'network-only'
      })
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }, [navigate, queries])

  const activateManagerSkipForLeave = useCallback(async () => {
    try {
      await queries.activateManagerSkipForLeave('Onboarding', {
        fetchPolicy: 'network-only'
      })
    } catch (error) {
      if (error instanceof Error) {
        navigate(errorPageRoute, {
          state: { error: error.message }
        })
      }
    }
  }, [navigate, queries])

  useEffect(() => {
    fetchLeave()
    // eslint-disable-next-line
  }, [])

  const finishSetup = useCallback(async () => {
    if (activateManager === ActivateManagerEnum.Now) {
      await activateManagerForLeave()
    } else if (activateManager === ActivateManagerEnum.Later) {
      await activateManagerSkipForLeave()
    }
    onShowDialog()
    navigate(destination)
  }, [
    navigate,
    destination,
    onShowDialog,
    activateManager,
    activateManagerForLeave,
    activateManagerSkipForLeave
  ])

  const reset = useCallback(() => {
    setActivateManager(null)
  }, [])

  const nextStage = useCallback(async () => {
    const newStage = workflow.moveNext()
    if (newStage) {
      setCurrentStage(newStage)
    } else {
      finishSetup()
    }
  }, [workflow, finishSetup])

  const prevStage = useCallback(async () => {
    const newStage = workflow.moveBack()
    if (newStage) {
      setCurrentStage(newStage)
    }
    if (newStage === 'getreminders') {
      reset()
    }
  }, [reset, workflow])

  const currentContent = useMemo(() => {
    switch (currentStage) {
      case 'getreminders':
        return <GetReminders />
      case 'setupManager':
        return <SetupManager />

      default:
        return null
    }
  }, [currentStage])

  const contextValue: IPublishedLeaveContext = {
    user: userResult.user,
    leave,
    nextStage,
    prevStage,
    activateManager,
    setActivateManager,
    setShowProgress
  }
  workflow.setContext(contextValue)

  if (isFetching || userResult.loading) {
    return <LoadingSpinner fullScreen fadesIn />
  }
  if (userResult.error || !userResult.user) {
    return null
  }

  return (
    <Page>
      {showProgress && (
        <ProgressBar current={progress.current} total={progress.total} />
      )}
      <Context.Provider value={contextValue}>{currentContent}</Context.Provider>
    </Page>
  )
})

PublishedLeavePage.displayName = 'PublishedLeavePage'

export default withDialogManager(withQueries(withRouter(PublishedLeavePage)))
