import React, { useCallback, useEffect, useState, useContext } from 'react'
import { Button } from 'src/UIKit'
import styled, { css } from 'styled-components'
import withRouter from 'src/components/hooks/useRouter'
import UserProfileContainer from 'src/features/UserProfile/UserProfileContainer'
import useUser, { IUseUser } from 'src/graphql/hooks/useUser'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { MetricEventRemindersImpression } from 'src/constants/metrics'
import useCreateMetric from 'src/graphql/hooks/useCreateMetric'
import { mobileButtonMaxWidthMixin } from 'src/theme/utils'
import { useTranslation } from 'react-i18next'
import { pageButtons } from 'src/constants/frame'
import PublishedLeaveView from 'src/components/pages/PublishedLeavePage/components/PublishedLeaveView'
import Context, {
  IPublishedLeaveContext
} from 'src/components/pages/PublishedLeavePage/context'

const ButtonsContainer = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  ${props =>
    props.theme.isMobile &&
    css`
      flex: 1;
      align-items: flex-end;
      margin-bottom: 16px;
    `}

  ${mobileButtonMaxWidthMixin};
`

const SkipButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: ${pageButtons.minWidth().px};
        `
      : css`
          width: 100%;
        `}
`

const NextButtonWrapper = styled(Button)`
  ${props =>
    props.theme.isDesktop
      ? css`
          min-width: ${pageButtons.minWidth().px};
        `
      : css`
          width: 100%;
        `}
`

export const dataAttrs = {
  buttonSkip: () => 'get-reminders-skip-button',
  buttonNext: () => 'get-reminders-next-button'
}

export const GetReminders = React.memo(() => {
  const { t } = useTranslation()
  const [user, setUser] = useState<IUser>(null)
  const [hasConfirmation, setHasConfirmation] = useState<boolean>(false)
  const { nextStage } = useContext<IPublishedLeaveContext>(Context)

  useCreateMetric({ eventType: MetricEventRemindersImpression }, !user)

  useEffect(() => {
    if (!user || !user.confirmations || user.confirmations.length === 0) {
      setHasConfirmation(false)
      return
    }

    for (const confirmation of user.confirmations) {
      if (confirmation.confirmedAt) {
        setHasConfirmation(true)
        return
      }
    }
  }, [user])

  const onUserUpdated = useCallback((u: IUser) => {
    setUser(u)
  }, [])

  const userResult: IUseUser = useUser()
  if (userResult.loading) {
    return <LoadingSpinner fullScreen fadesIn />
  }

  if (userResult.error || !userResult.user) {
    return null
  }

  return (
    <PublishedLeaveView name={'getReminders'}>
      <UserProfileContainer showsFormOnly onUserUpdated={onUserUpdated} />
      <ButtonsContainer>
        {!hasConfirmation && (
          <SkipButtonWrapper
            data-testid={dataAttrs.buttonSkip()}
            appearance={'unbordered'}
            onClick={nextStage}
          >
            {t('common.skip')}
          </SkipButtonWrapper>
        )}
        <NextButtonWrapper
          data-testid={dataAttrs.buttonNext()}
          disabled={!hasConfirmation}
          onClick={nextStage}
        >
          {t('common.next')}
        </NextButtonWrapper>
      </ButtonsContainer>
    </PublishedLeaveView>
  )
})

GetReminders.displayName = 'GetReminders'

export default withRouter(GetReminders)
